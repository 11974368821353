import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { oldWebsiteDTO } from '../../models/app'
import {
  ChangePasswordDTO,
  changePasswordResponseDTO,
  CompanyDTO,
  CreateUserDTO,
  EmailConfirmDTO,
  MemberEnrollmentDTO,
  ResendEmailDTO,
  AuthAcceptTerms,
  MemberEnrollmentRequest,
  MemberEnrollmentResponse,
} from '../../models/auth';
import { AppConfigService } from './app-config.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  apiName = 'authService';
  webApiName = 'webApi';
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values: any) => values.every((b: any) => b)));

  constructor(private oauthService: OAuthService, private http: HttpClient) {}
  public setAuthConfig(additionalParam: string | null, additionalParam2: string | null): void {
    const authConfig = AppConfigService.settings.authentication;
    if (additionalParam) {
      authConfig.customQueryParams = { code: additionalParam, virtual_path: additionalParam2 };
    }
    this.oauthService.configure(authConfig);

    this.oauthService.events.subscribe((_: any) => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.setupAutomaticSilentRefresh();
  }
  public runInitialLoginSequence(): Promise<void> {
    return this.oauthService
      .loadDiscoveryDocument()
      .then(() => this.oauthService.tryLoginCodeFlow())
      .then(() => {
        this.isDoneLoadingSubject$.next(true);
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public hasRole(role: string) {
    let claims: any = this.oauthService.getIdentityClaims();
    if (claims && claims.groups) {
      let roles: string[] = claims.groups;
      roles = roles.map(role => role.toUpperCase());
      return roles.includes(role.toLocaleUpperCase());
    }
    return false;
  }

  public getFullname() {
    let claims: any = this.oauthService.getIdentityClaims();
    if (claims && claims.name) {
      return claims.name;
    }
    return undefined;
  }

  public getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  public login() {
    this.oauthService.initCodeFlow();
  }
  public logout() {
    this.oauthService.logOut();
  }
  public refresh() {
    this.oauthService.silentRefresh();
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }
  get isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }
  refreshToken(): Promise<any> {
    return this.oauthService.refreshToken().then(response => {
      return response;
    });
  }
  async loadUserProfile(): Promise<void> {
    if (this.oauthService.hasValidAccessToken()) {
      await this.refreshToken(); // Wait for the refreshToken to complete
      this.oauthService.loadUserProfile().then(profile => {
        return profile;
      });
    }
  }
  updateUserPassword(updatePassword: ChangePasswordDTO) {
    let url_ = `${AppConfigService.authApiURl(this.apiName)}/user/change-password`;
    return this.http.post<changePasswordResponseDTO>(url_, updatePassword);
  }
  validateCompany(tpaCompanyId: string): Observable<CompanyDTO> {
    let url_ = `${AppConfigService.authApiURl(this.apiName)}/user/validate-company/{tpaCompanyId}`;
    url_ = url_.replace('{tpaCompanyId}', tpaCompanyId);
    return this.http.get<CompanyDTO>(url_);
  }
  validateMemberEnrollment(enrollment: MemberEnrollmentDTO): Observable<MemberEnrollmentDTO> {
    let url_ = `${AppConfigService.authApiURl(this.apiName)}/user/member-enrollment-info`;
    return this.http.post<MemberEnrollmentDTO>(url_, enrollment);
  }
  createUser(createUser: CreateUserDTO): Observable<boolean> {
    let url_ = `${AppConfigService.authApiURl(this.apiName)}/user/create-user`;
    return this.http.post<boolean>(url_, createUser);
  }
  confirmEmail(emailConfirm: EmailConfirmDTO): Observable<any> {
    let url_ = `${AppConfigService.authApiURl(this.apiName)}/user/email-confirm`;
    return this.http.put<any>(url_, emailConfirm);
  }
  resendEmail(resendEmail: ResendEmailDTO): Observable<any> {
    let url_ = `${AppConfigService.authApiURl(this.apiName)}/user/resend-email`;
    return this.http.post<any>(url_, resendEmail);
  }
  switchToOldSite(oldWebsite: oldWebsiteDTO) {
    let url_ = `${AppConfigService.webApiURL(this.webApiName)}/SSO/Generate`;
    return this.http.post<any>(url_, oldWebsite);
  }
  acceptTerms(acceptTerms: AuthAcceptTerms): Observable<boolean> {
    let url = AppConfigService.authApiURl(this.apiName) + '/user/acceptterms';
    return this.http.post<boolean>(url, acceptTerms);
  }
  addOrUpdateCompanyPayroll(IdCompanyPayrollCalendarMiscData: number, idCompany: number, DeptID: string, PayCycle: string): Observable<boolean> {
    let url = `${AppConfigService.authApiURl(this.apiName)}/user/AddOrUpdateCompanyPayollCalendarMiscData?`;
    url = url + "IdCompanyPayollCalendarMiscData="+ IdCompanyPayrollCalendarMiscData;
    url = url + "&IdCompany="+ idCompany;
    url = url + "&DeptID="+ DeptID;
    url = url + "&PayCycle="+ PayCycle;
     
    return this.http.post<boolean>(url,null);
  }
  addUpdateMember(enrollmentRequest: MemberEnrollmentRequest): Observable<MemberEnrollmentResponse> {
    let url_ = `${AppConfigService.authApiURl(this.apiName)}/user/AddUpdateMember`;
    return this.http.post<MemberEnrollmentResponse>(url_, enrollmentRequest);
  }
}
