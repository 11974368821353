import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  CommandColumnService,
  EditService,
  ExcelExportService,
  FilterService,
  GridModule,
  GroupService,
  PageService,
  PagerModule,
  PdfExportService,
  ReorderService,
  ResizeService,
  SearchService,
  SortService,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { BenefitDeadlineComponent } from './components/benefit-deadline/benefit-deadline.component';
import { AboutMyCardComponent } from './components/card-shared/about-my-card.component';
import { CardActivityComponent } from './components/card-shared/card-activity.component';
import { NewCardComponent } from './components/card-shared/new-card.component';
import { NoCardComponent } from './components/card-shared/no-card.component';
import { SpendingRulesComponent } from './components/card-shared/spending-rules.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SFGridComponent } from './components/sf-grid/sf-grid.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ToastComponent } from './components/toast/toast.component';
import { AddFundsToCardComponent } from './modal/add-fund-to-card/add-funds-to-card.component';
import { AlertModalComponent } from './modal/alert-modal/alert-modal.component';
import { BalanceTransferModalComponent } from './modal/balance-transfer-modal/balance-transfer-modal.component';

import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { BankInfoComponent } from './components/bank-info/bank-info.component';
import { ActivateCardComponent } from './components/card-shared/activate-card.component';
import { ClosedCardsComponent } from './components/card-shared/closed-cards.component';
import { MobileWalletComponent } from './components/card-shared/mobile-wallet.component';
import { PcccSmsComponent } from './components/card-shared/pccc-sms.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { MemberSubsidyComponent } from './components/member-subsidy/member-subsidy.component';
import { NavbarLogoutComponent } from './components/navbar/navbar-logout.component';
import { PcccStatementComponent } from './components/pccc-statement/pccc-statement.component';
import { EbAppRoleDirective } from './directives/eb-app-role.directive';
import { MaxValueDirective } from './directives/maxvalue.directive';
import { MinValueDirective } from './directives/minvalue.directive';
import { OnlyNumberDirective } from './directives/numberonly.directive';
import { OnlyDateDirective } from './directives/onlydate.directive';
import { OnlyDecimalDirective } from './directives/onlydecimal.directive';
import { PhoneNumberDirective } from './directives/phone.directive';
import { ZipCodeDirective } from './directives/zipCode.directive';
import { AcceptTermsModalComponent } from './modal/accept-terms-modal/accept-terms-modal.component';
import { ConfirmModalComponent } from './modal/confirm-modal/confirm-modal.component';
import { ConfirmationActivateCardComponent } from './modal/confirmation-activate-card/confirmation-activate-card.component';
import { CustomDateModalComponent } from './modal/custom-date-modal/custom-date-modal.component';
import { DeleteCreditcardModalComponent } from './modal/delete-creditcard-modal/delete-creditcard-modal.component';
import { EditProfileModalComponent } from './modal/edit-profile-modal/edit-profile-modal.component';
import { EsignModalComponent } from './modal/esign-modal/esign-modal.component';
import { HelpModalComponent } from './modal/help-modal/help-modal.component';
import { HistoryLetterReportComponent } from './modal/history-letter-report-modal/history-letter-report.component';
import { LostpassdetailsModalComponent } from './modal/lostpassdetails-modal/lostpassdetails-modal.component';
import { MarketPlaceModalComponent } from './modal/marketplace-modal/marketplace-modal.component';
import { MessageComponent } from './modal/message/message.component';
import { NeverRcvdCardModalComponent } from './modal/never-rcvd-card-modal/never-rcvd-card-modal.component';
import { NotificationComponent } from './modal/notification/notification.component';
import { PrkCashoutUnenrollmentComponent } from './modal/prk-cashout-unenrollment/prk-cashout-unenrollment.component';
import { ProductsNotcoveredModalComponent } from './modal/products-notcovered-modal/products-notcovered-modal.component';
import { ReportLostModalComponent } from './modal/report-lost-modal/report-lost-modal.component';
import { RequestPinModalComponent } from './modal/request-pin-modal/request-pin-modal.component';
import { RidePandaModalComponent } from './modal/ride-panda-modal/ride-panda-modal.component';
import { ScheduleActivityDetailComponent } from './modal/schedule-activity-detail/schedule-activity-detail.component';
import { SpendingGroupModalComponent } from './modal/spending-group-modal/spending-group-modal.component';
import { UserDetailModalComponent } from './modal/user-detail-modal/user-detail-modal.component';
import { DateAgoPipe } from './pipes/date-ago-format.pipe';
import { EBCurrencyPipe } from './pipes/ebCurrency.pipe';
import { SafeHTMLPipe } from './pipes/safeHTML.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    MessageComponent,
    AddFundsToCardComponent,
    ScheduleActivityDetailComponent,
    HistoryLetterReportComponent,
    SafeHTMLPipe,
    AlertModalComponent,
    HelpModalComponent,
    UserDetailModalComponent,
    BalanceTransferModalComponent,
    MarketPlaceModalComponent,
    SpendingGroupModalComponent,
    StepperComponent,
    ToastComponent,
    SFGridComponent,
    SpendingRulesComponent,
    AboutMyCardComponent,
    CardActivityComponent,
    EBCurrencyPipe,
    DateAgoPipe,
    ReportLostModalComponent,
    RequestPinModalComponent,
    BenefitDeadlineComponent,
    PhoneNumberDirective,
    MaxValueDirective,
    MinValueDirective,
    NavbarLogoutComponent,
    NoCardComponent,
    NewCardComponent,
    EditProfileModalComponent,
    OnlyNumberDirective,
    OnlyDateDirective,
    OnlyDecimalDirective,
    ClosedCardsComponent,
    ErrorMessageComponent,
    EbAppRoleDirective,
    ProductsNotcoveredModalComponent,
    NeverRcvdCardModalComponent,
    LostpassdetailsModalComponent,
    PcccStatementComponent,
    EsignModalComponent,
    CustomDateModalComponent,
    ActivateCardComponent,
    ZipCodeDirective,
    MobileWalletComponent,
    PcccSmsComponent,
    AcceptTermsModalComponent,
    DeleteCreditcardModalComponent,
    ConfirmationActivateCardComponent,
    PrkCashoutUnenrollmentComponent,
    ConfirmModalComponent,
    RidePandaModalComponent,
    BankInfoComponent,
    NotificationComponent,
    MemberSubsidyComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MdbFormsModule,
    MdbSelectModule,
    MdbTooltipModule,
    RouterModule,
    GridModule,
    PagerModule,
    ToolbarModule,
    MatTooltipModule,
    MdbTabsModule,
    MdbDatepickerModule,
  ],
  exports: [
    SFGridComponent,
    NavbarComponent,
    FooterComponent,
    MessageComponent,
    AddFundsToCardComponent,
    ScheduleActivityDetailComponent,
    HistoryLetterReportComponent,
    StepperComponent,
    SpendingRulesComponent,
    AboutMyCardComponent,
    CardActivityComponent,
    EBCurrencyPipe,
    DateAgoPipe,
    SafeHTMLPipe,
    BenefitDeadlineComponent,
    PhoneNumberDirective,
    MaxValueDirective,
    MinValueDirective,
    NavbarLogoutComponent,
    NoCardComponent,
    NewCardComponent,
    OnlyNumberDirective,
    OnlyDateDirective,
    OnlyDecimalDirective,
    ClosedCardsComponent,
    EbAppRoleDirective,
    PcccStatementComponent,
    ZipCodeDirective,
    MobileWalletComponent,
    PcccSmsComponent,
    BankInfoComponent,
    MemberSubsidyComponent,
  ],
  providers: [
    SortService,
    FilterService,
    GroupService,
    EditService,
    ExcelExportService,
    SearchService,
    PdfExportService,
    ReorderService,
    CommandColumnService,
    ToolbarService,
    ResizeService,
    PageService,
  ],
  bootstrap: [],
})
export class SharedModule {}
